<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadEngagementCharts="reloadCharts()"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Engagement</h3>
    <div class="graphics-container-triple">
      <div class="tway-violet--text">Compromiso con la Transformación Digital</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Adherencia a Metodologías Ágiles
              <ToolTips
                width="250"
                description="Inclusión y uso habitual de metodologías ágiles en proyectos e iniciativas digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartAdherenceMethodologies.series"
              :options="chartAdherenceMethodologies.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Adopción Tecnologías
              <ToolTips
                width="350px"
                description="Uso y adherencia a tecnologías, herramientas, plataformas o funcionalidades específicas por parte de usuarios en el tiempo."
                formula="(Cantidad de tecnologías disruptivas utilzadas) x 100 / Total"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartTechnologyAdoption.series"
              :options="chartTechnologyAdoption.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Conversión
              <ToolTips
                width="240px"
                description="Cantidad de usuarios que realiza una acción específica en un ambiente digital (uso, clic, descarga, registro, etc.)."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartCircleConversionRate.series"
              :options="chartCircleConversionRate.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="graphics-container-triple">
      <div class="tway-violet--text">Retención de Clientes</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Retención
              <ToolTips
                width="300px"
                description="Ratio que mide la fidelidad de los clientes a un servicio, producto o negocio en un periodo de tiempo de un mes."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartRetentionRate"
              :series="chartRetentionRate.series"
              :options="chartRetentionRate.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Conversión
              <ToolTips
                width="250px"
                description="Cantidad de usuarios que realiza una acción específica (uso, clic, descarga, registro, etc.)"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartConversionRate"
              :series="chartConversionRate.series"
              :options="chartConversionRate.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Win Back
              <ToolTips
                width="300px"
                description="Cantidad de ex-clientes o usuarios digitales inactivos que se recuperan como clientes o usuarios activos."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartWinBackRate"
              :series="chartWinBackRate.series"
              :options="chartWinBackRate.options"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="graphics-container-duo padding-fix">
      <div class="tway-violet--text">Valor de la Vida del Cliente</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              LTV de Cliente Digital
              <ToolTips
                width="300px"
                description="Valor neto de los ingresos que genera un cliente digital al negocio durante su tiempo de permanencia como cliente."
              />
            </p>
            <div class="text-sum">Total LTV mes anterior (1): {{ monetaryUnit + lastMonthLTV }}</div>
            <div class="text-sum">Total LTV mes actual (0): {{ monetaryUnit + thisMonthLTV }}</div>
          </div>
          <div>
            <VueApexCharts
              type="heatmap"
              height="300"
              :series="chartDigitalClient.series"
              :options="chartDigitalClient.options"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              % Mejora de LTV por Metodologías Ágiles
              <ToolTips
                width="300px"
                description="Atribución a metodologías ágiles en el incremento del indicador LTV (Valor de vida del cliente)."
              />
            </p>
            <div class="text-sum" style="color: white">.</div>
            <div class="text-sum" style="color: white">.</div>
          </div>
          <div>
            <VueApexCharts
              ref="chartAgileMethodologies"
              v-if="chartAgileMethodologies.series.length > 0"
              :series="chartAgileMethodologies.series"
              :options="chartAgileMethodologies.options"
              height="300"
              type="bar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/engagement/seguimiento.vue";
import EngagementService from "../../services/pmo/engagement/engagementService";
import LtvService from "../../services/pmo/ltv/ltvService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";
import BudgetService from "../../services/pmo/budget/budgetService";

//import ltvService from "../../services/pmo/ltv/ltvService";

export default {
  name: "Engagement",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      // chartSummaryEngagement: {
      //   series: [],
      //   options: {
      //     series: [
      //       {
      //         name: "% Retensión",
      //         data: [32, 38, 43, 46, 30, 35, 23, 31, 34, 32, 41, 45],
      //       },
      //     ],
      //     chart: {
      //       height: 450,
      //       type: "area",
      //       stacked: false,
      //       zoom: { enabled: false },
      //     },
      //     dataLabels: { enabled: false },
      //     stroke: {
      //       width: [2],
      //       curve: "smooth",
      //       dashArray: [0],
      //     },
      //     markers: { size: 3 },
      //     title: {
      //       text: undefined,
      //     },
      //     grid: {
      //       row: {
      //         colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //         opacity: 1.8,
      //       },
      //     },
      //     xaxis: {
      //       categories: [
      //         "Enero",
      //         "Febrero",
      //         "Marzo",
      //         "Abril",
      //         "Mayo",
      //         "Junio",
      //         "Julio",
      //         "Agosto",
      //         "Septiembre",
      //         "Octubre",
      //         "Noviembre",
      //         "Diciembre",
      //       ],
      //     },
      //     colors: ["#16C62E"],
      //     yaxis: [
      //       {
      //         axisTicks: { show: true },
      //         axisBorder: {
      //           show: true,
      //           color: "#16C62E",
      //         },
      //         labels: { style: { colors: "#16C62E" } },
      //         title: {
      //           text: "Retensión",
      //           style: { color: "#16C62E" },
      //         },
      //         max: 100,
      //         min: 0,
      //         tooltip: { enabled: true },
      //       },
      //     ],
      //     tooltip: {
      //       fixed: {
      //         enabled: true,
      //         position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
      //         offsetY: 30,
      //         offsetX: 60,
      //       },
      //     },
      //     legend: {
      //       horizontalAlign: "left",
      //       offsetX: 40,
      //     },
      //   },
      // },
      chartRetentionRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "%",
                style: { color: "#7319D5" },
              },
              max: 100,
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartConversionRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "area",
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          colors: [
            // "#7319D5",
            // "#B2B2B2",
            // "#AEEA00",
            // "#4A148C",
            // "#7F7F7F",
            "#16C62E",
          ],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "%",
                style: { color: "#16C62E" },
              },
              tooltip: { enabled: true },
              max: 100,
              min: 0,
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartWinBackRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "area",
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: [
            "#7F7F7F",
            // "#16C62E",
            // "#7319D5",
            // "#B2B2B2",
            // "#4A148C",
            // "#AEEA00",
          ],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "%",
                style: { color: "#7F7F7F" },
              },
              tooltip: { enabled: true },
              max: 100,
              min: 0,
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },

      chartDigitalClient: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "heatmap",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            heatmap: {
              radius: 6,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#7F7F7F", "#16C62E", "#7319D5", "#B2B2B2", "#4A148C", "#AEEA00"].reverse(),
          xaxis: {
            type: "category",
            name: "Hola",
            categories: [],
          },
          title: {
            text: undefined,
          },
          grid: {
            padding: {
              right: 20,
            },
          },
        },
      },
      chartAgileMethodologies: {
        series: [],
        options: {
          series: [],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 450,
            stacked: false,
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          colors: ["#16C62E", "#7319D5", "#B2B2B2", "#4A148C", "#AEEA00", "#7F7F7F"],
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 5,
              columnWidth: "100%",
              barHeight: "100%",
              distributed: false,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            text: undefined,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            min: 0,
            max: 100,
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " %";
              },
            },
          },
          fill: {
            opacity: 0.7,
            type: "solid",
            legend: {
              position: "top",
              horizontalAlign: "left",
              offsetX: 40,
            },
          },
          dataLabels: {
            style: {
              color: ["black"],
            },
          },
        },
      },

      chartAdherenceMethodologies: {
        series: [],
        options: {
          series: [75],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            colors: ["#ffffff"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          stroke: { lineCap: "round" },
          labels: [""],
        },
      },
      chartTechnologyAdoption: {
        series: [],
        options: {
          series: [83],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            color: ["#4A148C"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /* dropShadow: {
                   enabled: true,
                   top: 3,
                   left: 0,
                   blur: 4,
                   opacity: 0.24
                 }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      chartCircleConversionRate: {
        series: [],
        options: {
          series: [],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7F7F7F"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      thisMonthLTV: 0,
      lastMonthLTV: 0,
      monetaryUnit: "",
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await EngagementService.getRetentionRate(this.project)
        .then((data) => {
          this.chartRetentionRate.series = data.series;
          this.$refs.chartRetentionRate.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await EngagementService.getConversionRate(this.project)
        .then((data) => {
          this.chartConversionRate.series = data.series;
          this.$refs.chartConversionRate.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await EngagementService.getWinBackRate(this.project)
        .then((data) => {
          this.chartWinBackRate.series = data.series;
          this.$refs.chartWinBackRate.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await EngagementService.getAgileMethodologies(this.project)
        .then((data) => {
          let cat = data.series[0].dates;
          this.chartAgileMethodologies.series = data.series;
          this.chartAgileMethodologies.options.xaxis.categories = cat;
        })
        .catch((err) => console.dir(err));
      await EngagementService.getAdherenceMethodologies(this.project)
        .then((data) => {
          this.chartAdherenceMethodologies.series = data.series;
        })
        .catch((err) => console.dir(err));
      await EngagementService.getTechnologyAdoption(this.project)
        .then((data) => {
          this.chartTechnologyAdoption.series = data.series;
        })
        .catch((err) => console.dir(err));
      await EngagementService.getCircleConversionRate(this.project)
        .then((data) => {
          this.chartCircleConversionRate.series = data.series;
        })
        .catch((err) => console.dir(err));
      await LtvService.getChartLtv(this.project)
        .then((data) => {
          this.chartDigitalClient.series = data.serie;
        })
        .catch((err) => {
          console.dir(err);
        });
      await LtvService.getLtvSum(this.project)
        .then((data) => {
          this.thisMonthLTV = data.now;
          this.lastMonthLTV = data.last;
        })
        .catch((err) => {
          console.dir(err);
        });
      await BudgetService.getTableCostCenter(this.project)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 230px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}

.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 60%;
  height: auto;
}

.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  height: 500px;
}

.seguimiento-costo {
  width: 45%;
  margin: 2px;
}

.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}

.graphics-container-duo,
.graphics-container-triple {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px 0px 20px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 45%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 32%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.text-sum {
  font-size: 0.8em;
  color: #7f7f7f;
}
.padding-fix {
  padding-bottom: 20px;
}
</style>
