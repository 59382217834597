<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>

      <v-card :class="'modalSystem'">
        <v-btn icon right @click="dialog = false" class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card>
          <div class="container">
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Seguimiento de Engagement</h3>
            <h4 class="text-center font-weight-regular">Ingresar nuevo Engagement</h4>
            <div class="col-12 mt-8">
              Retención de Clientes
              <v-divider class="divisor"></v-divider>
            </div>
            <v-row class="mt-6">
              <v-col md="4">
                <v-text-field
                  v-model="headerEngagement.adherenceMethodology"
                  label="Adherencia metodologías"
                  :hide-details="true"
                  :maxlength="'15'"
                  :minlenght="'0'"
                  type="text"
                  outlined
                  class="edit"
                />
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="headerEngagement.technologyAdoption"
                  label="Adopción tecnologías"
                  :hide-details="true"
                  :maxlength="'15'"
                  :minlenght="'0'"
                  type="text"
                  outlined
                />
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="headerEngagement.conversionRateColab"
                  label="Tasa de conversión"
                  :hide-details="true"
                  :maxlength="'15'"
                  :minlenght="'0'"
                  type="text"
                  outlined
                />
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <!-- Agregar Engagements y Listado -->
            <h3 class="text-center my-5">Añadir Engagement</h3>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="170" scope="col">Mes / Año</th>
                    <th class="text-center" width="14%" scope="col">Tasa de retención</th>
                    <th class="text-center" width="14%" scope="col">Tasa de Conversión</th>
                    <th class="text-center" width="14%" scope="col">Tasa de Win Back</th>
                    <th class="text-center" width="12%" scope="col">$ LTV</th>
                    <th class="text-center" width="14%" scope="col">% Mejora de LTV</th>
                    <th class="text-center" width="150" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr :v-if="items.length > 0" v-for="item in items" :key="item.index">
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.tasaRetencion"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.tasaConversion"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.tasaWin"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.ltv"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.mejoraLtv"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-btn
                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                        outlined
                        color="indigo lighten-2"
                        @click="addCustomerEngagement(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                        Agregar
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <v-alert
              v-model="alert.saveEngagement"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveEngagement }}
            </v-alert>
            <!-- LISTADO SEGUIMIENTO ENGAGEMENT -->
            <h4 class="text-center font-weight-regular">Listado de Seguimiento Engagement</h4>
            <v-alert
              v-model="alert.updateEngagement"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.updateEngagement }}
            </v-alert>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="150" scope="col">Mes / Año</th>
                    <th class="text-center" width="15%" scope="col">Tasa de retención</th>
                    <th class="text-center" width="15%" scope="col">Tasa de Conversión</th>
                    <th class="text-center" width="15%" scope="col">Tasa de Win Back</th>
                    <th class="text-center" width="15%" scope="col">$ LTV</th>
                    <th class="text-center" width="15%" scope="col">% Mejora de LTV</th>
                    <th class="text-center" width="150" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="customerEngagements.length > 0"
                    v-for="item in customerEngagements"
                    :key="item.index"
                    :class="item.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.retentionRate"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.conversionRateCustomer"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.winBackRate"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.customerLtv"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.methodologyLtv"
                        item-value="15"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(item)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="editCustomerEngagement(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteEngagement(item.index)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <!-- AÑADIR NUEVO DATO LTV -->
            <h3 class="text-center my-5">Añadir Datos LTV</h3>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" scope="col">Fecha Registro de Clientes</th>
                    <th class="text-center" scope="col">Fecha Registro de Datos</th>
                    <th class="text-center" scope="col">$ LTV</th>
                    <th class="text-center" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr :v-if="ltvItems.length > 0" v-for="item in ltvItems" :key="item.index">
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.customerRegistration"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.customerRegistration" type="month" no-title scrollable>
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu2"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.dataRegistration"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.dataRegistration" type="month" no-title scrollable>
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.ltv"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-btn
                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                        outlined
                        color="indigo lighten-2"
                        @click="saveLtv()"
                      >
                        <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                        Agregar
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <v-alert
              v-model="alert.saveLtvAlert"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveLtvMessage }}
            </v-alert>
            <!-- LISTADO SEGUIMIENTO LTV -->
            <h4 class="text-center font-weight-regular">Listado de Seguimiento LTV</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="110" scope="col">Mes \ Valor</th>
                    <th class="text-center" width="6%" scope="col">0</th>
                    <th class="text-center" width="6%" scope="col">1</th>
                    <th class="text-center" width="6%" scope="col">2</th>
                    <th class="text-center" width="6%" scope="col">3</th>
                    <th class="text-center" width="6%" scope="col">4</th>
                    <th class="text-center" width="6%" scope="col">5</th>
                    <th class="text-center" width="6%" scope="col">6</th>
                    <th class="text-center" width="6%" scope="col">7</th>
                    <th class="text-center" width="6%" scope="col">8</th>
                    <th class="text-center" width="6%" scope="col">9</th>
                    <th class="text-center" width="6%" scope="col">10</th>
                    <th class="text-center" width="6%" scope="col">11</th>
                    <th class="text-center" width="6%" scope="col">12</th>
                    <th class="text-center" width="105" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="ltvTable.length > 0"
                    v-for="item in ltvTable"
                    :key="item.index"
                    :class="item.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.customerDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.customerDate" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <!-- <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[0].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td> -->
                    <td v-for="entry in item.amountList" :key="entry.index" class="text-center">
                      <v-text-field
                        v-model="entry.y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>

                    <!-- <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[2].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[3].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[4].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[5].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[6].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[7].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[8].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[9].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[10].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[11].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.amountList[12].y"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td> -->
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(item)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="save" @click="updateLtv(item)" v-bind="attrs" v-on="on">
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteLtvDataList(item)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar x</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row>
              <v-col md="12" class="justify-center">
                <div class="btn d-flex justify-center my-8">
                  <v-btn
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text justify-center ml-3"
                    outlined
                    color="indigo lighten-2"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text"> save </v-icon>
                    Guardar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EngagementService from "@/services/pmo/engagement/engagementService";
import LtvService from "../../../services/pmo/ltv/ltvService";
export default {
  data: () => ({
    dialog: false,
    items: [
      {
        date: new Date().toISOString().substr(0, 7),
        tasaRetencion: 0,
        tasaConversion: 0,
        tasaWin: 0,
        ltv: 0,
        mejoraLtv: 0,
      },
    ],
    ltvItems: [
      {
        customerRegistration: new Date().toISOString().substr(0, 7),
        dataRegistration: new Date().toISOString().substr(0, 7),
        ltv: 0,
      },
    ],
    nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
    menu: false,
    isVisible: false,
    activeClass: "is-visible",
    isShow: true,
    engagements: [],
    headerEngagement: {},
    customerEngagements: [],
    maxIndex: 0,
    ltvTable: [],
    alert: {
      saveLtvAlert: false,
      saveEngagement: false,
      updateEngagement: false,
    },
    messageError: {
      saveLtvMessage: "",
      saveEngagement: "",
      updateEngagement: "",
    },
  }),
  methods: {
    refreshEngagement() {
      this.$emit("reloadEngagementCharts");
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.updateEngagement();
      this.refreshEngagement();
    },
    AddItem() {
      this.items.push({
        date: this.date,
        tasaRetencion: this.tasaRetencion,
        tasaConversion: this.tasaConversion,
        tasaWin: this.tasaWin,
        ltv: this.ltv,
        mejoraLtv: this.mejoraLtv,
      });
    },
    removeItem() {
      if (this.items.length > 1) {
        this.items.splice(this.items, 1);
      }
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    clearForm() {
      this.items[0].date = "";
      this.items[0].tasaRetencion = 0;
      this.items[0].tasaConversion = 0;
      this.items[0].tasaWin = 0;
      this.items[0].ltv = 0;
      this.items[0].mejoraLtv = 0;
      /*  this.maxIndex = this.maxIndex + 1; */
    },
    clearLtvForm() {
      this.ltvItems[0].customerRegistration = new Date().toISOString().substr(0, 7);
      this.ltvItems[0].dataRegistration = new Date().toISOString().substr(0, 7);
      this.ltvItems[0].ltv = 0;
    },
    getEngagements() {
      EngagementService.getEngagements(this.projectIds)
        .then((data) => {
          this.engagements = data;
          if (data.length > 0) {
            this.headerEngagement = data[0];
          }
        })
        .catch((error) => console.dir(error));
    },
    updateEngagement() {
      const request = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        adherenceMethodology: this.headerEngagement.adherenceMethodology,
        technologyAdoption: this.headerEngagement.technologyAdoption,
        conversionRate: this.headerEngagement.conversionRateColab,
      };
      EngagementService.updateEngagement(request)
        .then(() => {
          this.getEngagements();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updateEngagement = true;
          this.messageError.updateEngagement = message;
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.updateEngagement = false), 4000);
        });
    },
    getCustomerEngagements() {
      EngagementService.getCustomerEngagements(this.projectIds)
        .then((data) => {
          this.customerEngagements = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editCustomerEngagement(item) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        date: item.date,
        retentionRate: item.retentionRate,
        conversionRateCustomer: item.conversionRateCustomer,
        winBackRate: item.winBackRate,
        customerLtv: item.customerLtv,
        methodologyLtv: item.methodologyLtv,
        index: item.index,
      };

      EngagementService.addCustomerEngagements(data)
        .then(() => {
          this.item = [];
          this.getCustomerEngagements();
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updateEngagement = true;
          this.messageError.updateEngagement = message;
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.updateEngagement = false), 4000);
        });
    },
    addCustomerEngagement(item) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        date: item.date,
        retentionRate: item.tasaRetencion,
        conversionRateCustomer: item.tasaConversion,
        winBackRate: item.tasaWin,
        customerLtv: item.ltv,
        methodologyLtv: item.mejoraLtv,
        index: this.maxIndex + 1,
      };

      EngagementService.addCustomerEngagements(data)
        .then(() => {
          this.item = [];
          this.getCustomerEngagements();
          this.getIndexMax();
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          if (err.response.data == "") {
            message = "Formato de dato de entrada no valido.";
          }
          this.alert.saveEngagement = true;
          this.messageError.saveEngagement = message;
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.saveEngagement = false), 4000);
        });
    },
    getIndexMax() {
      EngagementService.getIndexMax(this.projectIds)
        .then((data) => {
          this.maxIndex = data;
        })
        .catch((err) => console.dir(err));
    },
    deleteEngagement(index) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        index: index,
      };
      EngagementService.deleteEngagementCustomer(data)
        .then((data) => {
          console.log(data);
          this.getCustomerEngagements();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.refreshEngagement();
        });
    },
    saveLtv() {
      const request = {
        enterpriseId: this.projectIds.enterpriseId,
        projectId: this.projectIds.projectId,
        customerRegistration: this.ltvItems[0].customerRegistration,
        dataRegistration: this.ltvItems[0].dataRegistration,
        amount: this.ltvItems[0].ltv,
      };
      LtvService.saveLTV(request)
        .then(() => {
          this.getTableLtv();
          this.clearLtvForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveLtvAlert = true;
          this.messageError.saveLtvMessage = message;
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.saveLtvAlert = false), 4000);
        });
    },
    updateLtv(item) {
      const request = {
        enterpriseId: this.projectIds.enterpriseId,
        projectId: this.projectIds.projectId,
        customerDate: item.customerDate,
        amountList: item.amountList,
      };
      LtvService.editLtv(request)
        .then(() => {
          this.getTableLtv();
          this.saveItem();
        })
        .catch((err) => {
          // let message = err.response.data.message;
          // this.alert.saveLtvAlert = true;
          // this.messageError.saveLtvMessage = message;
          console.dir(err);
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.saveLtvAlert = false), 4000);
        });
    },
    getTableLtv() {
      LtvService.getTableLtv(this.projectIds)
        .then((data) => {
          this.ltvTable = data;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    deleteLtvDataList(item) {
      const request = {
        enterpriseId: this.projectIds.enterpriseId,
        projectId: this.projectIds.projectId,
        customerDate: item.customerDate,
        amountList: item.amountList,
      };
      LtvService.deleteLtv(request)
        .then(() => {
          this.getTableLtv();
          this.saveItem();
        })
        .catch((err) => {
          // let message = err.response.data.message;
          // this.alert.saveLtvAlert = true;
          // this.messageError.saveLtvMessage = message;
          console.dir(err);
        })
        .finally(() => {
          this.refreshEngagement();
          setTimeout(() => (this.alert.saveLtvAlert = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.projectIds = pmoProjectIds;

    this.getEngagements();
    this.getCustomerEngagements();
    this.getIndexMax();
    this.getTableLtv();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem .v-data-table__wrapper > table {
  min-width: 1000px;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.formSeg .formRow {
  display: none;
}
.formSeg .formRow:last-child {
  display: flex;
}
.edit_row
  .v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined
  .v-input__prepend-outer {
  display: none;
}
.v-data-table__wrapper .edit_row th.text-center {
  color: rgba(0, 0, 0, 0.6);
}
.edit_row .v-text-field--outlined .v-input__prepend-outer {
  display: none !important;
}
.data-table > .v-data-table__wrapper > table > thead > tr > th,
.text-center {
  padding: 0 5px !important;
}
</style>
